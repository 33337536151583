(function () {
  'use strict'

  angular
    .module('wedding')
    .component('mainComponent', {
      template: `

      <style>
      html,
body {


  /* background-color: #333; */
  font-family: LinuxLibertine;
  /* letter-spacing: 0.1em; */
  text-transform: uppercase;
  /* overflow-x: hidden; */
padding-top:0px !important;

}
      </style>

      <div class="homebg">
      <div class="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">

        <main role="main" class="inner cover">
            <h1 class="cover-heading text-center" style="font-family: LinuxLibertine; letter-spacing: 8px; padding-top:15px;">
                <div style="padding-left:8px;">DANIEL</div>
                <img src="/img/heart.svg" style="height:35px; position:relative; top:-5px;">
                <div style="padding-left:8px;">BRITT</div>
            </h1>
            <p class="lead" style="padding-top:10px;">
            <form ng-submit="$ctrl.invite($ctrl.inviteCode)" novalidate>
            <div class="input-group mb-3">
            <input
            ng-model="$ctrl.inviteCode"
            style="font-family: LinuxLibertine; letter-spacing: 5px; background-color: rgba(255, 255, 255, 0.5)"
            class="form-control form-control-lg text-uppercase text-center" type="text"
            placeholder="Invite Code" auto-focus>
  <div class="input-group-append">
    <button ladda="$ctrl.loading" style="background-color: rgba(255, 255, 255, 0.5); font-family: LinuxLibertine; letter-spacing: 2px; border: .05rem solid rgba(255, 255, 255, 0.5);" class="btn btn-secondary text-uppercase" type="submit" id="button-addon2">Submit</button>
  </div>
</div>
                
                    <!--button type="submit" value="Submit">Submit</button-->
            </form>

            </p>
        </main>

        <footer class="mastfoot mt-auto">
        </footer>

    </div>
    </div>
      `,
      controller: MainController,
      controllerAs: '$ctrl',
      bindings: {
        Binding: '='
      }
    })

  MainController.$inject = ['ngToast', '$state', '$localStorage', '$timeout', '$rootScope', '$scope']
  function MainController (ngToast, $state, $localStorage, $timeout, $rootScope, $scope) {
    var $ctrl = this

    $ctrl.$onInit = function () {
      console.log($localStorage)
      if ($localStorage.code) {
        $timeout(function () {
          $state.go('invite', { 'code': $localStorage.code })
          ngToast.create({
            content: 'Nice! you&#39;ve already entered a valid code!',
            className: 'success'
          })
        })
      }
    }

    $rootScope.$on('codeVerificationLoading', function (event, args) {
      $ctrl.loading = args;
    })

    $ctrl.invite = invite

    function invite () {
      // $rootScope.loading = true
      if (!$ctrl.inviteCode) {
        ngToast.create({
          content: 'Please enter a code!',
          className: 'danger'
        })
        // $rootScope.loading = false
      } else {
        $state.go('invite', { code: $ctrl.inviteCode })
      }
    }
  }
})()
