(function () {
  'use strict'

  angular
    .module('wedding')
    .service('apiService', apiService)

  apiService.$inject = ['$http']
  function apiService ($http) {
    this.verifyCode = verifyCode
    this.retrieveGroupGuests = retrieveGroupGuests
    this.updateGroupGuests = updateGroupGuests
    /// /////////////

    function verifyCode (inviteCode) {
      // return $http.get(`http://localhost:3001/inviteCode/${inviteCode}`)

      return $http({
        url: 'php/verifyCode.php',
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data: $.param({ groupId: inviteCode })
      })
    }

    function retrieveGroupGuests (groupId) {
      return $http({
        url: 'php/retrieveGroupGuests.php',
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data: $.param({ groupId: groupId })
      })
    }

    function updateGroupGuests (send) {
      console.log(send);
      return $http({
        url: 'php/updateGroupGuests.php',
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data: $.param({ send: send })
      })
      // return $http.put(`http://localhost:3001/group/`, send)
    }
  }
})()
