(function () {
  'use strict'

  angular
    .module('wedding')
    .component('invite', {
      template: `<nav  class="navbar fixed-top navbar-expand-lg navbar-dark bg-dark color2" style="box-shadow: 0 0 0.5rem 0.2rem #000;">

  <a class="navbar-brand" href="#">DANIEL <i class="fa fa-heart"></i> BRITT</a>

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText"
    aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarText">
    <ul du-spy-context class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" href="#home" du-smooth-scroll du-scrollspy duration="800">Home</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#location" offset="56" du-smooth-scroll du-scrollspy duration="800">Location</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#accommodation" offset="56" du-smooth-scroll du-scrollspy duration="800">Accommodation</a>
      </li>
      <!-- li class="nav-item">
        <a class="nav-link" href="#travel" offset="56" du-smooth-scroll du-scrollspy>Travel</a>
      </li -->
      <li class="nav-item" ng-if="$ctrl.group.groupType === 'day'">
        <a class="nav-link" href="#food" offset="56" du-smooth-scroll du-scrollspy duration="800">Food</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#gifts" offset="56" du-smooth-scroll du-scrollspy duration="800">Gifts</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#rsvp" offset="56" du-smooth-scroll du-scrollspy duration="800">RSVP</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#contact" offset="56" du-smooth-scroll du-scrollspy duration="800">Contact</a>
      </li>

    </ul>
      <div class="navbar-text linuxnotransform" style="color:#fff;">
      <i class="fa fa-calendar-alt"></i> {{$ctrl.timeTilWedding}}
      </div>
  </div>
</nav>


<div id="home" class="jumbotron jumbotron-fluid topSectionBg d-flex flex-wrap justify-content-center align-items-center"
  style="background-color:#B7C7D6;  color:#394855; margin-bottom: 0px;  height:350px;">
  <div class="container text-center d-flex flex-wrap justify-content-center align-items-center">
    <h1 class="cover-heading text-center" style="font-family: LinuxLibertine; letter-spacing: 8px; padding-top:15px;">

      <div style="padding-left:8px;">DANIEL</div>

      <svg id="svg2" style="height:35px; position:relative; top:-5px;" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 645 585">
        <defs>
          <style>
            .cls-1 {
              fill: #e53287;
            }
          </style>
        </defs>
        <title>Artboard 1</title>
        <g id="layer1">
          <path id="path2417" class="cls-1"
            d="M297.3,550.87c-13.78-15.44-48.17-45.53-76.44-66.88-83.74-63.24-95.14-72.39-129.14-103.7C29,322.57,2.41,264.58,2.5,186c0-38.38,2.67-53.17,13.41-75.8C34.15,71.77,61,43.24,95.36,25.8,119.69,13.44,131.68,8,172.3,7.73c42.5-.24,51.44,4.72,76.44,18.45C279.16,42.9,310.48,78.62,317,104l4,15.67,9.86-21.58c55.72-122,233.6-120.15,295.5,3,19.64,39.08,21.8,122.51,4.38,169.51-22.71,61.31-65.38,108.05-164,179.68-64.68,47-137.89,118.05-143,128C317.79,589.92,323.42,580.14,297.3,550.87Z" />
        </g>
      </svg>

      <div style="padding-left:8px;">BRITT</div>

    </h1>
  </div>
</div>

<div id="location" class="container-fluid color2 whiteText">

  <!-- Text on top of each other -->
  <div class="row " style="box-shadow: 0 0 0.5rem 0.1rem #000;">
    <div class="col d-flex flex-wrap justify-content-center align-items-center"
      style="padding-top:30px; padding-bottom:20px;">
      <div class="text-center">
        <h2>September 15<sup style="font-size:50%;">th</sup> - 12:30pm</h2>
        
      </div>
    </div>
  </div>

            <!-- text-shadow: 0px 0px 15px #333e61; -->

  <div class="row sopleyBg">
    <div class="col d-flex flex-wrap justify-content-center align-items-center "
      style="height: 400px; padding-top:20px; padding-bottom:20px;">
      <div class="text-center">
        <p class="deluxe color2Text" style="font-size:5rem; text-shadow: 0.1rem 0.1rem 0rem #fff, -0.1rem -0.1rem 0rem #fff, 0.1rem -0.1rem 0rem #fff, -0.1rem 0.1rem 0rem #fff, 0px 0px 15px #333e61;">Sopley Mill</p>
        <p class="whiteText" style="padding-bottom:20px; font-family:LinuxLibertine; font-size:2rem; font-weight:bold; letter-spacing: 0.3rem; text-shadow:  0px 0px 15px #333e61,  0px 0px 15px #333e61;" class="whiteText">Mill Lane<br />
          Christchurch<br />
          BH23 7AU</p>
        <a href="http://sopleymill.co.uk/" target="blank" class="btn btn-secondary" role="button">Click here for
          more information!</a>
      </div>
    </div>
  </div>

</div>

<div id="accommodation" class="container-fluid color1 color2Text" style="padding-top:50px; ">

  <div class="row">
    <div class="col text-center">
      <h1 class="d-none d-sm-block" style="padding-bottom:40px;"><i class="fa fa-bed"></i> Accommodation</h1>
      <h3 class="d-block d-sm-none" style="padding-bottom:40px;"><i class="fa fa-bed"></i> Accommodation</h3>
    </div>
  </div>

  <div class="row text-center">

    <!-- Fishermans Haunt -->
    <div class="col d-flex flex-wrap justify-content-center align-items-center" style="padding-bottom:50px;">
      <div class="card" style="width: 18rem;">
        <img class="card-img-top" src="/img/fishermansHaunt.jpg" alt="Card image cap">
        <div class="card-body">
          <h5 class="card-title">The Fishermans Haunt</h5>
          <p class="card-text linuxnotransform">On the edge of the village of Winkton, only 2 minutes from sopley mill!</p>
          <a href="https://www.fishermanshauntdorset.co.uk/" target="blank" class="btn btn-primary primarybuttonover">Website</a>
          <a href="https://goo.gl/maps/H3yWA7bSGqG2" target="blank" class="btn btn-success successbuttonover">Map</a>
        </div>
      </div>
    </div>

    <!-- Avon Causeway Hotel -->
    <div class="col d-flex flex-wrap justify-content-center align-items-center" style="padding-bottom:50px;">
      <div class="card" style="width: 18rem;">
        <img class="card-img-top" src="/img/avonCauseway.jpg" alt="Card image cap">
        <div class="card-body">
          <h5 class="card-title">Avon Causeway Hotel</h5>
          <p class="card-text linuxnotransform">Lovely little inn on the edge of the new forest, have a great selection of gins &amp;
            wines! ;)</p>
          <a href="https://www.avoncauseway.co.uk/" target="blank" class="btn btn-primary primarybuttonover">Website</a>
          <a href="https://goo.gl/maps/PTFVXnD7oNE2" target="blank" class="btn btn-success successbuttonover">Map</a>
        </div>
      </div>
    </div>

    <!-- Travelodge Christchurch-->
    <div class="col d-flex flex-wrap justify-content-center align-items-center" style="padding-bottom:50px;">
      <div class="card" style="width: 18rem;">
        <img class="card-img-top" src="/img/travelLodge.jpg" alt="Card image cap">
        <div class="card-body">
          <h5 class="card-title">Travelodge Christchurch</h5>
          <p class="card-text linuxnotransform">Perfectly situated in Saxon Square, you're right in the middle of town</p>
          <a href="https://www.travelodge.co.uk/hotels/586/Christchurch-hotel" target="blank"
            class="btn btn-primary primarybuttonover">Website</a>
          <a href="https://goo.gl/maps/1jLtLntLPup" target="blank" class="btn btn-success successbuttonover">Map</a>
        </div>
      </div>
    </div>

    <!-- Premier Inn Christchurch-->
    <div class="col d-flex flex-wrap justify-content-center align-items-center" style="padding-bottom:50px;">
      <div class="card" style="width: 18rem;">
        <img class="card-img-top" src="/img/premierInn.jpg" alt="Card image cap">
        <div class="card-body">
          <h5 class="card-title">Premier Inn Christchurch West</h5>
          <p class="card-text linuxnotransform">Close by to the Groom's parents house should you wish to pay a visit!</p>
          <a href="https://www.premierinn.com/gb/en/hotels/england/dorset/christchurch/christchurch-west.html"
            target="blank" class="btn btn-primary primarybuttonover">Website</a>
          <a href="https://goo.gl/maps/Bs5ipoiMaVy" target="blank" class="btn btn-success successbuttonover">Map</a>
        </div>
      </div>
    </div>

  </div>



</div>

<div id="food" class="container-fluid color2 whiteText" style="padding:50px 0px 0px 0px; " ng-if="$ctrl.group.groupType === 'day'">
  <div class="container">
    <div class="row">

      <div class="col d-flex flex-wrap justify-content-center align-items-center">

        <div class="text-center" style="">
          <h1 style="padding:00px 0px 30px 0px;">
          <i class="fa fa-utensils"></i>
          The Food</h1>
          <p style="padding:00px 0px 30px 0px;" class="linux">At our wedding breakfast we'll be serving paella!
            There are three choices: Chicken &amp; Chorizo, Vegetarian or Seafood. All options can be made gluten
            free.
            For dessert there will be a big sharing platter of chocolatey bits, fruit and cheeses.
            Later on in the day we will be serving a selection of tapas to keep you topped up for all the
            dancing!<br /><br />
            If there are any issues with the food, please dont hesitate to let us know
            (you can find both of our phone numbers at the bottom of the website!)
        </div>

      </div>

    </div>
  </div>

</div>





<div class="container-fluid " ng-if="$ctrl.group.groupType === 'day'">


  <div class="row">

    <!-- Meat -->
    <div class="col-md-4 d-flex flex-wrap justify-content-center align-items-center text-center"
      style="height:300px; background-image:url(/img/chicken-chorizo-paella.jpeg); background-position:center;">
      <h1 style="color:#ffffff; text-shadow: 3px 3px 15px #000;">Chicken &amp; Chorizo</h1>
    </div>

    <!-- Veg-->
    <div class="col-md-4 d-flex flex-wrap justify-content-center align-items-center"
      style="height:300px; background-image:url(/img/vegetarian-paella.jpg); background-position:center;">
      <h1 style="color:#ffffff; text-shadow:2px 2px 15px #000;">Vegetarian</h1>
    </div>

    <!-- Seafood-->
    <div class="col-md-4 d-flex flex-wrap justify-content-center align-items-center"
      style="height:300px; background-image:url(/img/seafood-paella.jpg); background-position:center;">
      <h1 style="color:#ffffff; text-shadow:2px 2px 15px #000;">Seafood</h1>
    </div>

  </div>

</div>

<div id="gifts" class="container-fluid color1 color2Text " style="padding:50px 0px 50px 0px; ">
  <div class="container">
    <div class="row">
      <div class="col d-flex flex-wrap justify-content-center align-items-center">
        <div class="text-center" style="">
          <h1 style="padding:00px 0px 30px 0px;">
          <i class="fa fa-plane"></i> Gifts <i class="fa fa-gifts"></i></h1>
          <p style="padding:00px 0px 30px 0px;" class="linux color2Text">
            If you'd like to send us a gift, which we'd greatly appreciate, we're hoping to go on a nice holiday next year for our honeymoon!
          </p>
          <a class="btn btn-lg btn-primary primarybuttonover" href="https://paypal.me/pools/c/8cTEDWvkSO" target="blank"> Click here for our paypal money pool</a>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="rsvp" class="container-fluid rsvpSectionBg">

  <div class="container" style="padding-top:50px;">
    <div class="row">
      <div class="col">

      <div ng-if="$ctrl.formSubmitted && !$ctrl.editing" class="jumbotron color2 whiteText" style="box-shadow: 0 0 1rem 0.2rem #667196;">
        <h1 class="text-center d-none d-sm-block">{{$ctrl.group.groupName}}</h1>
        <h3 class="text-center d-block d-sm-none">{{$ctrl.group.groupName}}</h3>
        <h4 class="text-center">Thanks for sending us your RSVP!</h4>
        <button type="button" ng-click="$ctrl.editing = true" class="btn btn-success btn-lg btn-block text-uppercase formbuttonover">Need to update something?</button>
        <p class="linuxnotransform text-center" style="color:#e53287; padding-top:30px;">* Note: The option to update your RSVP will be removed on the 1st August 2019! (This is so we can give concrete numbers to our caterers)</p>
      </div>

        <form ng-if="$ctrl.editing" name="guestForm" ng-submit="$ctrl.submit()" novalidate>
          <div class="jumbotron color2 whiteText" style="box-shadow: 0 0 1rem 0.2rem #667196;">
          
          
            <h1 class="text-center d-none d-sm-block">{{$ctrl.group.groupName}} RSVP</h1>
            <h3 class="text-center d-block d-sm-none">{{$ctrl.group.groupName}} RSVP</h3>
            <p class="text-center linuxnotransform"><span style="color:#e53287">*</span> = Required</p>
            <hr class="my-4 color1">
            <div ng-repeat="guest in $ctrl.groupGuests">

              <div class="row">
                <div class="col">
                  <h3>{{guest.firstName}} <i style="color:#039347" class="fa fa-check" ng-if="guest.mealChoice !== null && guest.coming !== null"></i></h3>
                </div>
              </div>

              <div class="form-row">

                <div class="form-group col-md">
                  <label for="inputState"><span style="color:#e53287">*</span> Coming</label>
                  <select ng-model="guest.coming" id="inputState" class="form-control form-control-lg" required>
                    <option selected value>Choose...</option>
                    <option class="text-success" value="Y">Yep! 😄🎉👰🤵</option>
                    <option class="text-danger" value="N">I can't make it 😢</option>
                  </select>

                </div>

                <div class="form-group col-md" ng-if="$ctrl.group.groupType === 'day' && guest.coming === 'Y'">
                  <label for="inputState"><span style="color:#e53287">*</span> Meal Choice</label>
                  <select ng-model="guest.mealChoice" class="form-control form-control-lg" ng-required="(guest.coming === 'Y')" ng-disabled="(guest.coming === 'N')">
                    <option selected value>Choose...</option>
                    <option value="meat">Chicken &amp; Chorizo 🍖</option>
                    <option value="veg">Vegetarian 🥕</option>
                    <option value="fish">Seafood 🦐</option>
                  </select>
                </div>

                <div class="form-group col-md" ng-if="$ctrl.group.groupType === 'day' && guest.coming === 'Y'">
                  <label for="textthing">Dietary Requirements</label>
                  <input ng-model="guest.dietaryRequirements" id="textthing" class="form-control form-control-lg"
                    type="text" placeholder="Gluten Free, Allergies, etc"  ng-disabled="(guest.coming === 'N')">
                </div>

              </div>
              <hr class="my-4 color1">

            </div>

            <div class="form-row">
              <h3 for="textthing">Email Address <span style="color:#e53287" class="linuxnotransform" ng-if="guestForm.email.$invalid">You've entered an invalid email address!</span>  <i style="color:#039347" class="fa fa-check" ng-if="guestForm.email.$valid && $ctrl.group.email.length > 0"></i></h3>
              <input name="email" type="email" class="form-control form-control-lg" ng-model="$ctrl.group.email" placeholder="Email Address">
              <label style="padding-top:0.4rem;" class="linuxnotransform">We'll send you an email when we update the website with more info!</label>
            </div>

            <hr class="my-4 color1">

            <div class="form-row">
              <button type="submit" ladda="$ctrl.updateLoading"  ng-disabled="guestForm.$invalid" class="btn btn-success btn-lg btn-block text-uppercase formbuttonover">
                <span ng-if="$ctrl.editing && $ctrl.formSubmitted">Update</span>
                <span ng-if="!$ctrl.editing || ($ctrl.editing && !$ctrl.formSubmitted)">Submit</span>
              </button>
            </div>


          </div>

        </form>
      </div>
    </div>

  </div>


</div>

<div id="contact" class="color2 whiteText container-fluid d-flex flex-wrap justify-content-center align-items-center"
  style="padding-top:50px; padding-bottom:50px;">
  <div class="text-center">
    <h1><i class="fa fa-mobile-alt"></i> Contact</h1>
    <h3>Dan: <a href="tel:+447759148204">+447759148204</a></h3>
    <h3>Britt: <a href="tel:+447882728807">+447882728807</a></h3>
  </div>

</div>`,

      controller: InviteController,
      controllerAs: '$ctrl',
      bindings: {
        code: '='
      }
    })

  InviteController.$inject = ['apiService', '$stateParams', '$interval', '$localStorage']
  function InviteController (apiService, $stateParams, $interval, $localStorage) {
    var $ctrl = this

    // TODO:
    // * taxi numbers
    // * order of the day
    // * photography #danandbritt2019

    $ctrl.submit = function () {
      $ctrl.send = { guests: $ctrl.groupGuests, email: $ctrl.group.email }
      console.log($ctrl.send)
      $ctrl.updateLoading = true
      apiService.updateGroupGuests($ctrl.send)
        .then(function (response) {
          console.log(response.data)
          if (response.data === 'true') {
            $ctrl.updateLoading = false
            $ctrl.formSubmitted = true
            $ctrl.editing = false
          }
        }).catch(function (e) {
          console.error(e)
        })
    }

    $ctrl.countdown = function () {
      // Set the date we're counting down to
      var countDownDate = new Date('September 15, 2019 12:00:00').getTime()

      // Update the count down every 1 second
      var x = $interval((function timer () {
        // Get todays date and time
        var now = new Date().getTime()

        // Find the distance between now and the count down date
        var distance = countDownDate - now

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24))
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        var seconds = Math.floor((distance % (1000 * 60)) / 1000)

        // Display the result in the element with id="demo"
        $ctrl.timeTilWedding = days + ' days to go!'

        // + hours + ' hours ' + minutes + ' minutes ' + seconds + ' seconds'
        // If the count down is finished, write some text
        if (distance < 0) {
          clearInterval(x)
          $ctrl.timeTilWedding = "We're Married!"
        }

        return timer
      }()), 1000)
    }

    $ctrl.$onInit = function () {
      $ctrl.formSubmitted = false
      $ctrl.editing = false
      console.log($ctrl.code)

      if (!$localStorage.code) {
        $localStorage.code = $stateParams.code
      }

      $ctrl.countdown()

      apiService.retrieveGroupGuests($stateParams.code)
        .then(function (response) {
          console.log(response.data)

          const group = response.data[0][0]
          const groupGuests = response.data[1]

          $ctrl.formSubmitted = groupGuests.every(checkForm)
          if ($ctrl.formSubmitted === false) {
            $ctrl.editing = true
          }
          console.log($ctrl.formSubmitted)

          $ctrl.group = group
          $ctrl.groupGuests = groupGuests
          console.log(groupGuests)
        }).catch(function (e) {
          console.error(e)
        })
    }

    function checkForm (guest) {
      return guest.coming !== null
    }

    $ctrl.$onChanges = function (changesObj) { }
    $ctrl.$onDestroy = function () { }
  }
})()
