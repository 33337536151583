
angular.module('wedding').config(function ($urlRouterProvider, $stateProvider, $locationProvider, ngToastProvider) {
  ngToastProvider.configure({
    verticalPosition: 'bottom',
    horizontalPosition: 'center',
    maxNumber: 3
  })

  $locationProvider
    .html5Mode({
      enabled: false
      // requireBase: false
    })
    // .hashPrefix('#')

  // Home
  $stateProvider.state('home', {
    url: '/home',
    component: 'mainComponent', // The component's name
    resolve: {
      // No need for this yet!
      // users: function (UserService) {
      //   return UserService.list()
      // }
    }
  })

  // About
  $stateProvider.state('temp', {
    url: '/temp',
    component: 'invite', // The component's name
    resolve: {
      // No need for this yet!
      // users: function (UserService) {
      //   return UserService.list()
      // }
    }
  })

  // Home
  $stateProvider.state('invite', {
    url: '/invite',
    component: 'invite', // The component's name
    params: {
      code: null
    },
    resolve: {
      code: ['$stateParams', 'apiService', '$q', 'ngToast', '$state', '$localStorage', '$timeout', '$rootScope', function ($stateParams, apiService, $q, ngToast, $state, $localStorage, $timeout, $rootScope) {
        // console.log($stateParams.code)
        var deferred = $q.defer()

        if ($localStorage.code) {
          $timeout(function () {
            $state.go('invite', { 'code': $localStorage.code })
          })
        } else {
          $rootScope.$broadcast('codeVerificationLoading', true)
          apiService.verifyCode($stateParams.code)
            .then(function (response) {
              console.log(response)
              if (response.data === 'true') {
                deferred.resolve($stateParams.code)
              } else {
                ngToast.create({
                  content: 'Sorry, the code you&#39;ve entered hasnt been recognised ',
                  className: 'danger'
                })
                $state.go('home')
                deferred.reject()
              }
              $rootScope.$broadcast('codeVerificationLoading', false)
            })
            .catch(function (e) {
              console.log(e)
              ngToast.create({
                content: 'Sorry, the code you&#39;ve entered hasnt been recognised ',
                className: 'danger'
              })
              $state.go('home')
              $rootScope.$broadcast('codeVerificationLoading', false)
              deferred.reject()
            })

          return deferred.promise
        }
      }]
    }
  })

  // Home
  // $stateProvider.state('shop', {
  //   url: '/shop',
  //   component: 'shop', // The component's name
  //   resolve: {
  //     // No need for this yet!
  //     // users: function (UserService) {
  //     //   return UserService.list()
  //     // }
  //   }
  // })

  // Home
  // $stateProvider.state('faq', {
  //   url: '/faq',
  //   component: 'faq', // The component's name
  //   resolve: {
  //     // No need for this yet!
  //     // users: function (UserService) {
  //     //   return UserService.list()
  //     // }
  //   }
  // })

  $urlRouterProvider.otherwise('/home')
})
