(function () {
  'use strict'

  angular.module('wedding', [
    'ui.router',
    'ngToast',
    'ngStorage',
    'duScroll',
    'angular-ladda'
  ])
})()
